<template>
  <div class="company">
    <div class="wrapper">
      <div class="companyHead">
        <div class="companyName">
          <span class="code" v-text="companyCode"></span>
          <span
            class="name"
            :class="{ skLoading: !getDetail('information.companyName') }"
            v-text="getDetail('information.companyName')"
          ></span>

          <div
            class="watchlist"
            :class="{
              'common-disabled': $store.getters['Portfolio/busy']
            }"
            :data-tooltip="isInPortfolios ? 'remove from watchlist' : 'add to watchlist'"
            @click="setPortfolio"
          >
            <Icon :icon="isInPortfolios ? 'star-full' : 'star'" />
          </div>

          <div class="fullPrice" :class="{ skLoading: !getDetail('information.companyName') }">
            <FullPriceChange :company="company" />
            <div class="updated">updated: {{ priceUpdatedDate }}</div>
          </div>
        </div>

        <div class="companyScore">
          <LuxBreakdown :company="company" />
        </div>

        <MediumWidget
          class="headChart"
          :symbols="chartSymbols"
          :chartOnly="true"
          :height="150"
          colorTheme="dark"
          gridLineColor="#000"
          trendLineColor="#fff"
          fontColor="#999"
          underLineColor="#fff"
          v-if="!$store.getters['Company/busy']"
        />

        <PageTabs class="tabs" :items="tabItems" :disabled="$store.getters['Company/busy']" />
      </div>

      <transition name="maxH">
        <div class="main" v-if="company.companyCode && !$store.getters['Company/busy']">
          <router-view v-slot="{ Component }">
            <transition mode="out-in" name="fade">
              <component :is="Component" :company="company" />
            </transition>
          </router-view>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useNavigation } from "@/components/renderless/navigation";
import _ from "lodash";
import Functions from "@/functions";
import Constants from "@/assets/constants.js";
import Button from "@/components/atoms/Button.vue";
import Icon from "@/components/atoms/Icon.vue";
import LuxBreakdown from "@/components/atoms/LuxBreakdown.vue";
import PageTabs from "@/components/atoms/PageTabs.vue";
import FullPriceChange from "@/components/atoms/FullPriceChange.vue";
import LoadingBar from "@/components/atoms/LoadingBar.vue";
import MediumWidget from "@/components/atoms/MediumWidget.vue";
export default {
  name: "Company",
  props: {
    companyCode: { type: String, default: null }
  },
  components: {
    Button,
    Icon,
    LuxBreakdown,
    PageTabs,
    FullPriceChange,
    LoadingBar,
    MediumWidget
  },
  setup(props) {
    const store = useStore();
    const navigation = useNavigation();

    const setPortfolio = () => {
      if (store.getters["Portfolio/isEmpty"]) {
        let addOrRemove = isInPortfolios.value ? "toRemove" : "toAdd";
        store.dispatch("Portfolio/addPortfolio", {
          name: "default",
          [addOrRemove]: [companyFullCode.value]
        });
      } else {
        let addOrRemove = isInPortfolios.value ? "deletePortfolioHolding" : "addPortfolioHolding";
        store.dispatch(`Portfolio/${addOrRemove}`, {
          id: store.getters["Portfolio/portfolios"][0].id,
          code: companyFullCode.value
        });
      }
    };

    const getData = code => {
      store.dispatch("Company/details", { code, fullDetails: true });
      store.dispatch("Portfolio/getPortfolios");
    };

    const getDetail = data => {
      return _.get(company.value, data, null);
    };

    const company = computed(() => {
      return store.getters["Company/company"](props.companyCode);
    });

    const tabItems = computed(() => {
      return [
        {
          label: "Overview",
          key: "overview",
          to: `/company/${props.companyCode}/overview`
        },
        {
          label: "Financial",
          key: "financial",
          to: `/company/${props.companyCode}/financial`
        },
        {
          label: "Announcements",
          key: "announcements",
          to: `/company/${props.companyCode}/announcements`
        },
        {
          label: "Peers",
          key: "peers",
          to: `/company/${props.companyCode}/peers`
        }
      ];
    });

    const companyFullCode = computed(() => {
      return _.get(company.value, "information.companyCode", props.companyCode);
    });

    const priceUpdatedDate = computed(() => {
      return Functions.Dates.dateText(
        _.get(company.value, 'companyData.priceChange["price.change.1D"].date', null),
        Constants.DATE_FORMAT.DAY_OF
      );
    });

    const isInPortfolios = computed(() => {
      let portfolios = store.getters["Portfolio/portfolios"];
      return !!portfolios.find(p => p.holdings.find(h => h.code == companyFullCode.value) || false);
    });

    const chartSymbols = computed(() => {
      let code = (company.value.companyCode || "").split(".")[0];
      return [`ASX:${code}|12M`];
    });

    onMounted(() => {
      getData(props.companyCode);
    });

    watch(
      () => props.companyCode,
      v => {
        if (v) {
          getData(v);
        }
      }
    );
    return {
      Functions,
      navigation,
      getDetail,
      company,
      tabItems,
      priceUpdatedDate,
      setPortfolio,
      isInPortfolios,
      chartSymbols
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.company {
  @include flexCentered();
  align-items: flex-start;

  .wrapper {
    width: 100%;
  }

  .companyLoading {
    width: 80%;
  }

  .companyHead {
    color: $color-white;
    background: $color-blue;
  }

  .head {
    background: lighten($color-blue-light, 3%);
    .watchlist {
      padding: addSpace(2);
      cursor: pointer;
      color: $color-blue;
    }
  }

  .companyName {
    @include flexCentered();
    justify-content: flex-start;
    padding: addSpace(4) addSpace(2);
    .code {
      font-size: 1.2em;
      font-weight: 600;
      padding: 0 addSpace(2);
    }
    .name {
      font-size: 0.9em;
      padding: 0 addSpace(1);
      min-width: 100px;
      min-height: 1em;
      margin-right: auto;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .watchlist {
      padding: addSpace(2);
      cursor: pointer;
    }
    .fullPrice {
      padding: addSpace(1) addSpace(2);
      white-space: nowrap;

      @include mq($to: medium) {
        font-size: 0.8em;
      }

      .priceChange {
        justify-content: flex-end;
      }
      .updated {
        font-size: 0.75em;
      }
    }
  }

  .companyScore {
    @include mq($to: medium) {
      height: 40px;
    }
  }

  .headChart {
    background: darken($color-blue, 10%);
  }

  .js-widget-chart-info {
    display: none;
  }

  .tabs {
    padding: addSpace(6) addSpace(2) 0;
    color: $color-dark-gray;
    background: $color-white;

    @include mq($to: medium) {
      padding-top: addSpace(2);
    }
  }

  .main {
    padding: addSpace(2);
  }
}
</style>
