<template>
  <div class="notice">
    <LoadingBar class="loading" :loading="$store.getters['Notice/busy']" :transparent="true" />
    <div class="wrapper">
      <div class="common-backbar">
        <div class="back" @click="navigation.smartBack(`/spotee-tv`)"><Icon icon="arrow-left" /> back</div>
      </div>

      <div class="main">
        <h3>Notice</h3>

        <div class="notice">
          <form v-on:submit.prevent="note" class="common-form" :class="{ 'common-form-loading': formData.busy }">
            <div class="form-content" :class="{ 'common-disabled': formData.busy }">
              <component
                :is="f.component"
                v-for="(f, k) in formData.fields"
                :key="f.key || k"
                v-bind="f.preset"
                v-model="f.value"
              />
              <transition name="maxH">
                <div class="common-form-error" v-if="formData.formError" v-t="formData.formError" />
              </transition>

              <div class="form-actions">
                <Button
                  v-bind="Functions.Presets.Button.Submit"
                  text="Send"
                  :disabled="!formData.canSubmit"
                  @click="note"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, markRaw, onMounted } from "vue";
import { useStore } from "vuex";
import { useForm } from "@/components/renderless/form";
import { useNavigation } from "@/components/renderless/navigation";
import Constants from "@/assets/constants.js";
import Functions from "@/functions";
import Button from "@/components/atoms/Button.vue";
import Icon from "@/components/atoms/Icon.vue";
import Input from "@/components/atoms/Input.vue";
import LoadingBar from "@/components/atoms/LoadingBar.vue";
import RadioInput from "@/components/atoms/RadioInput.vue";
import Textarea from "@/components/atoms/Textarea.vue";
export default {
  name: "NewNotice",
  props: {
    noteId: { type: String, default: null }
  },
  components: {
    Button,
    Icon,
    Input,
    LoadingBar,
    RadioInput,
    Textarea
  },
  setup(props) {
    const store = useStore();
    const navigation = useNavigation();

    const editMode = computed(() => !!props.noteId);

    const formData = reactive(
      useForm([
        {
          key: "title",
          component: markRaw(Input),
          preset: {
            ...Functions.Presets.Field.Field,
            label: "Title",
            color: "white"
          },
          value: ""
        },
        {
          key: "body",
          component: markRaw(Textarea),
          preset: {
            ...Functions.Presets.Field.RequiredField,
            label: "Body",
            color: "white"
          },
          value: ""
        },
        {
          key: "expiresOn",
          component: markRaw(Input),
          preset: {
            ...Functions.Presets.Field.Date,
            label: "Date",
            color: "success"
          }
        },
        {
          key: "displayOrder",
          component: markRaw(Input),
          preset: {
            ...Functions.Presets.Field.Integer,
            label: "Display order",
            color: "white"
          },
          value: 0
        },
        {
          key: "isPublic",
          component: markRaw(RadioInput),
          preset: {
            label: "Is it pubblic?",
            kind: "selector",
            iconPosition: "left",
            options: [
              {
                key: true,
                label: "Yes"
              },
              {
                key: false,
                label: "No"
              }
            ]
          },
          value: true
        }
      ])
    );

    const note = () => {
      if (!formData.canSubmit) {
        return;
      }
      formData.formError = "";
      formData.busy = true;
      let data = { ...formData.currentData };
      if (props.noteId) {
        data.id = parseInt(props.noteId);
      }
      data.effectiveOn = Functions.Dates.dateText(new Date(), Constants.DATE_FORMAT.ISO);
      let d = new Date(data.expiresOn);
      d.setDate(d.getDate() + 1);
      data.expiresOn = Functions.Dates.dateText(d, Constants.DATE_FORMAT.ISO);
      data.topic = Constants.NOTICE_TOPIC.UPCOMING;
      store
        .dispatch("Notice/editNotice", data)
        .then(
          () => {
            navigation.smartBack(`/spotee-tv`);
          },
          () => {
            formData.formError = "common.validation.generic";
            formData.busy = false;
          }
        )
        .finally(() => {
          formData.busy = false;
        });
    };

    const getData = () => {
      if (!currentNotice.value) {
        store
          .dispatch("Notice/getNotices", {
            topic: Constants.NOTICE_TOPIC.UPCOMING,
            includeFuture: true
          })
          .then(() => fillData());
      } else {
        fillData();
      }
    };

    const fillData = () => {
      formData.fields[0].value = currentNotice.value.title;
      formData.fields[1].value = currentNotice.value.body;
      let d = new Date(currentNotice.value.expiresOn);
      d.setDate(d.getDate() - 1);
      formData.fields[2].value = Functions.Dates.dateText(d, Constants.DATE_FORMAT.ISO);
      formData.fields[3].value = currentNotice.value.displayOrder;
      formData.fields[4].value = currentNotice.value.isPublic;
    };

    const currentNotice = computed(() => store.getters["Notice/notices"].find(n => n.id == props.noteId));

    onMounted(() => {
      getData();
    });
    return {
      Functions,
      navigation,
      editMode,
      formData,
      note,
      currentNotice
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.main {
  padding: addSpace(4) addSpace(2);

  h3 {
    color: $color-blue;
  }

  .notice {
    @include chatEdge;
    padding: addSpace(2);
    background: rgba($color-blue, 0.1);
  }
}
</style>
