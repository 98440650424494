<template>
  <div class="page">
    <div class="back">
      <Button v-bind="Functions.Presets.Button.Link" icon="arrow-left" text="back" @click="$emit('back')" />
    </div>
    <div class="title">
      Some final questions
    </div>

    <form v-on:submit.prevent="$emit('next')" class="common-form" :class="{ 'common-form-loading': busy }">
      <div class="form-content" :class="{ 'common-disabled': busy }">
        <component :is="f.component" v-for="(f, k) in fields" :key="f.key || k" v-bind="f.preset" v-model="f.value" />
        <div class="form-actions">
          <Button v-bind="Functions.Presets.Button.Link" text="Why are these important?" />
          <Button v-bind="Functions.Presets.Button.Submit" text="Next" :disabled="!canSubmit" @click="$emit('next')" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { markRaw } from "vue";
import { useForm } from "@/components/renderless/form";
import Functions from "@/functions";
import Button from "@/components/atoms/Button.vue";
import Input from "@/components/atoms/Input.vue";
import RadioInput from "@/components/atoms/RadioInput.vue";
export default {
  name: "Final",
  emits: ["next", "back"],
  components: {
    Button,
    Input,
    RadioInput
  },
  setup() {
    const formData = useForm([
      {
        key: "frequency",
        component: markRaw(RadioInput),
        preset: {
          label: "How often do look at your portfolio?",
          kind: "selector",
          options: [
            {
              key: "daily",
              label: "Daily"
            },
            {
              key: "weekly",
              label: "Weekly"
            }
          ],
          validation: v => !!v
        },
        value: ""
      },
      {
        key: "asx300",
        component: markRaw(RadioInput),
        preset: {
          label: "Limit results to the ASX300?",
          kind: "selector",
          options: [
            {
              key: "yes",
              value: "Yes"
            },
            {
              key: "no",
              value: "No"
            }
          ],
          validation: v => !!v
        },
        value: ""
      },
      {
        key: "liquidity",
        component: markRaw(Input),
        label: "What is your minimum liquidity requirement?",
        preset: Functions.Presets.Field.Currency,
        value: ""
      },
      {
        key: "sectorBan",
        component: markRaw(RadioInput),
        preset: {
          label: "Are their any sectors you wish to avoid?",
          kind: "selector",
          options: [
            {
              key: "yes",
              value: "Yes"
            },
            {
              key: "no",
              value: "No"
            }
          ],
          validation: v => !!v
        },
        value: ""
      },
      {
        key: "sectors",
        component: markRaw(RadioInput),
        preset: {
          kind: "selector",
          direction: "vertical",
          options: [
            {
              key: "mining",
              value: "Mining"
            },
            {
              key: "fossil-fuels",
              value: "Fossil fuels"
            },
            {
              key: "gambling",
              value: "Gambling"
            },
            {
              key: "others",
              value: "Others?"
            }
          ],
          validation: v => !!v
        },
        value: ""
      },
      {
        key: "sectors",
        component: markRaw(RadioInput),
        preset: {
          kind: "selector",
          label: "What is your view on dividends?",
          options: [
            {
              key: "important",
              value: "Important"
            },
            {
              key: "not-important",
              value: "Not Important"
            }
          ],
          validation: v => !!v
        },
        value: ""
      },
      {
        key: "sectors",
        component: markRaw(RadioInput),
        preset: {
          kind: "selector",
          label: "Do you want only profitable companies?",
          options: [
            {
              key: "yes",
              value: "Yes"
            },
            {
              key: "no",
              value: "No"
            }
          ],
          validation: v => !!v
        },
        value: ""
      }
    ]);

    return {
      Functions,
      busy: formData.busy,
      canSubmit: formData.canSubmit,
      currentData: formData.currentData,
      fields: formData.fields
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  @include flexCentered(column);
  align-items: stretch;
  @include maxWidth($size-breakpoint-small);
  padding: addSpace(2);

  .back {
    font-size: $text-small;
  }

  .title {
    font-size: $text-lead;
    margin: addSpace(2) 0;
  }

  .form-actions {
    @include flexCentered;
    justify-content: space-between;
    margin: addSpace(2) 0;
  }
}
</style>
