<template>
  <div class="textarea-field" :class="classObject">
    <label v-if="label" v-html="label"></label>
    <div class="hint" v-if="hint" v-html="hint"></div>
    <div class="textarea-field__wrapper">
      <textarea
        class="textarea-field__input"
        :aria-label="label"
        :disabled="disabled"
        :inputmode="inputmode"
        :max="max"
        :min="min"
        :maxlength="maxlength"
        :minlength="minlength"
        :name="name"
        :pattern="pattern"
        :placeholder="placeholder"
        :readonly="readonly"
        ref="inputRef"
        :step="step"
        :tabindex="tabindex"
        :type="type"
        :value="modelValue"
        @keydown="onKeyDown"
        @input="onInput"
        @paste="onInput"
        @focusin="onFocus"
        @blur="onBlur"
      />
      <Icon :icon="icon" class="textarea-field__icon" />
      <LoadingBar v-if="loading" class="textarea-field__loading" :loading="loading" :transparent="true" />
    </div>
    <div class="textarea-field__byline" v-if="byline" v-html="byline"></div>
    <transition name="maxH">
      <div
        class="textarea-field__validation-message"
        data-test="validation-message"
        v-if="errorMessage"
        v-html="$t(errorMessage, $props)"
      ></div>
    </transition>
  </div>
</template>

<script>
import { reactive, ref, computed, nextTick, onMounted, watch } from "vue";
import _ from "lodash";
import C from "@/assets/constants";
import Icon from "./Icon.vue";
import LoadingBar from "./LoadingBar.vue";
export default {
  name: "Textarea",
  components: {
    Icon,
    LoadingBar
  },
  props: {
    disabled: { type: Boolean, default: false },
    byline: { type: String, default: null },
    color: { type: String, default: "white" },
    forceinput: { type: [RegExp, String], default: null },
    hint: { type: String, default: null },
    icon: { type: String, default: null },
    iconPosition: { type: String, default: "right" },
    inputmode: { type: String, default: "text" },
    label: { type: String, default: null },
    loading: { type: Boolean, default: false },
    max: { type: [Number, Date, String], default: null },
    min: { type: [Number, Date, String], default: null },
    maxlength: { type: [Number, String], default: null },
    minlength: { type: [Number, String], default: null },
    modelValue: { type: [String, Number, Boolean], default: "" },
    name: { type: String, default: null },
    pattern: { type: [String, RegExp], default: null },
    placeholder: { type: String, default: null },
    readonly: { type: Boolean, default: false },
    resize: { type: String, default: "auto-grow" },
    rows: { type: Number, default: 4 },
    step: { type: [Number, String], default: null },
    tabindex: { type: Number, default: null },
    type: { type: String, default: "text" },
    validation: { type: Function, default: () => true },
    validation_message: { type: [String, Object], default: null }
  },
  emits: ["blur", "focus", "keydown", "update:modelValue", "valid"],
  setup(props, context) {
    const isActive = ref(false);
    const isChanged = ref(false);
    const isTouched = ref(false);
    const isValid = ref(true);
    const inputRef = ref(null);
    const formattedValue = ref("");
    const errorMessage = ref("");
    const tempValue = ref("");
    const lastKeyDown = reactive({});

    const autoExpand = () => {
      if (props.resize == "auto-grow") {
        let field = inputRef.value;
        if (field) {
          field.style.height = "inherit";
          let computed = window.getComputedStyle(field);
          let height =
            parseInt(computed.getPropertyValue("border-top-width"), 0) +
            field.scrollHeight +
            parseInt(computed.getPropertyValue("border-bottom-width"), 0);
          field.style.height = height + "px";
        }
      }
    };

    const focus = () => {
      inputRef.value.focus();
    };

    const validate = value => {
      let valid = props.validation ? props.validation(value) : true;
      isValid.value = !isTouched.value || valid === true;
      if (isValid.value) {
        errorMessage.value = "";
      } else {
        if (valid === false) {
          if (props.validation_message) {
            errorMessage.value = props.validation_message;
          } else {
            errorMessage.value = "";
          }
        } else {
          errorMessage.value = valid;
        }
      }
      context.emit("valid", isValid.value);
    };

    const onKeyDown = event => {
      lastKeyDown.target = {
        value: _.get(event, "target.value"),
        selectionStart: _.get(event, "target.selectionStart"),
        selectionEnd: _.get(event, "target.selectionEnd")
      };
      context.emit("keydown", event);
    };
    const onInput = event => {
      if (event.keyCode == C.KEYCODE.TAB || event.keyCode == C.KEYCODE.SHIFT) return;
      isChanged.value = true;
      if (event.keyCode == C.KEYCODE.ENTER && event.key == "Enter") {
        isTouched.value = true;
      }
      if (props.forceinput) {
        let match = event.target.value.match(props.forceinput) || !_.get(event, "target.value.length");
        if (!match || match.input !== match[0]) {
          event.target.value = _.get(lastKeyDown, "target.value") || "";

          if (_.lowerCase(event.target.type) in C.InputTypeForSelectionAttr) {
            event.target.selectionStart = _.get(lastKeyDown, "target.selectionStart");
            event.target.selectionEnd = _.get(lastKeyDown, "target.selectionEnd");
          }
        }
      }
      context.emit("update:modelValue", event.target.value);
      nextTick(() => {
        validate(props.modelValue);
      });
    };
    const onFocus = event => {
      isActive.value = true;
      context.emit("focus", event);
    };
    const onBlur = event => {
      isActive.value = false;
      if (isChanged) {
        isTouched.value = true;
      }
      nextTick(() => {
        validate(props.modelValue);
      });
      context.emit("blur", event);
    };

    const isRequired = computed(() => _.isFunction(props.validation) && !props.validation.call("", context));

    const classObject = computed(() => {
      return {
        ["textarea-field--type-" + props.type]: !!props.type,
        "textarea-field--has-icon": props.icon,
        ["textarea-field--color-" + props.color]: !!props.color,
        "textarea-field--icon-left": props.iconPosition === "left",
        "textarea-field--is-active": !!isActive.value,
        "textarea-field--is-require": !!isRequired.value,
        "textarea-field--error": !isValid.value,
        "textarea-field--readonly": props.readonly,
        "textarea-field--disabled": props.disabled,
        ["textarea-field--resize-" + props.resize]: !!props.resize
      };
    });

    watch(
      () => props.modelValue,
      () => {
        nextTick(() => {
          autoExpand();
        });
      }
    );
    onMounted(() => {
      autoExpand();
    });

    return {
      isActive,
      isChanged,
      isTouched,
      isValid,
      inputRef,
      formattedValue,
      errorMessage,
      tempValue,
      lastKeyDown,

      focus,
      validate,
      onKeyDown,
      onInput,
      onFocus,
      onBlur,

      isRequired,
      classObject
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.textarea-field {
  width: 100%;
  margin-top: addSpace(2);
  margin-bottom: addSpace(2);

  label {
    display: block;
    margin-bottom: addSpace();
    text-align: left;
    margin-left: addSpace(3);
    font-size: $text-small-lead;
  }

  .hint {
    margin-left: addSpace(4);
    margin-bottom: addSpace();
  }

  &__wrapper {
    position: relative;
    &:after {
      @include transition();
      display: block;
      content: close-quote;
      position: absolute;
      pointer-events: none;
      opacity: 0;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border: 1px solid $color-light;
      border-radius: 30px;
    }
  }

  &__icon {
    @include transition();
    position: absolute;
    top: 50%;
    right: addSpace(4);
    transform: translateY(-50%);
    pointer-events: none;
  }

  &--icon-left .textarea-field__icon {
    right: auto;
    left: addSpace(4);
  }

  &__input {
    @include transition();
    display: block;
    width: 100%;
    font-size: $text-note;
    padding: addSpace(2) addSpace(4);
    border: 1px solid transparent;
    outline: 0;
    outline-style: none;
    border-radius: 30px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::placeholder {
      color: getInputElementState(default, placeholderColor);
    }
    &:-ms-input-placeholder {
      color: getInputElementState(default, placeholderColor);
    }
  }

  &--resize-vertical .textarea-field__input {
    resize: vertical;
  }
  &--resize-horizontal .textarea-field__input {
    resize: horizontal;
  }
  &--resize-none .textarea-field__input,
  &--resize-auto-grow .textarea-field__input {
    resize: none;
  }

  &--has-icon .textarea-field__input {
    padding-right: addSpace(8);
  }

  &--icon-left .textarea-field__input {
    padding-right: addSpace(2);
    padding-left: addSpace(8);
  }

  &__byline {
    float: right;
    margin: addSpace(1) 0 0 addSpace(2);
  }

  &--color {
    &-blue {
      .textarea-field__input {
        color: $color-white;
        background: darken($color-blue, 5%);
      }
    }
    &-white {
      .textarea-field__input {
        color: $color-blue;
        background: $color-white;
      }
    }
    &-green {
      .textarea-field__input {
        color: $color-white;
        background: $color-success;
      }
    }
  }

  &--is-active {
    .textarea-field__wrapper:after {
      opacity: 1;
    }

    .textarea-field__input {
      color: getInputElementState(active, inputColor);
      border-color: getInputElementState(default, borderColor);
    }

    .textarea-field__icon {
      color: getInputElementState(active, iconFillActive);
    }
  }

  &--is-require {
    label::after {
      content: " *";
      color: $color-error;
    }
  }

  &--success {
    .textarea-field__input,
    .textarea-field__wrapper:after {
      color: getInputElementState(success, inputColor);
      border-color: getInputElementState(success, borderColor) !important;
    }
  }

  &--error {
    .textarea-field__input,
    .textarea-field__wrapper:after {
      color: $color-error;
      border-color: $color-error !important;
    }

    .textarea-field__icon {
      color: $color-error;
    }
  }

  &--readonly {
    pointer-events: none;
    .textarea-field__input {
      border-color: getInputElementState(disabled, borderColor);
    }
  }

  &--disabled {
    pointer-events: none;

    .textarea-field__input {
      color: getInputElementState(disabled, inputColor);
      border-color: getInputElementState(disabled, borderColor);
      background-color: getInputElementState(disabled, backgroundColor);

      &[type="range"] {
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &::-webkit-slider-runnable-track {
          background-color: getInputElementState(disabled, rangeColor);
        }

        &::-moz-range-progress {
          background-color: getInputElementState(disabled, rangeFillColor);
        }

        &::-moz-range-track {
          background-color: getInputElementState(disabled, rangeColor);
        }

        &::-ms-fill-lower {
          background-color: getInputElementState(disabled, rangeFillColor);
        }

        &::-ms-fill-upper {
          background-color: getInputElementState(disabled, rangeColor);
        }
      }
    }
  }

  &__loading {
    position: absolute !important;
    bottom: 0;
    left: addSpace(3);
    width: calc(100% - #{addSpace(6)});
    border-radius: 1em;
  }

  &__validation-message {
    text-align: left;
    padding-top: addSpace(2);
    padding-left: addSpace(3);
    color: $color-error;
  }
}
</style>
