<template>
  <div class="pageTabs">
    <router-link v-for="item in items" :key="item.key" class="item" :class="itemClasses(item)" :to="item.to">
      {{ item.label }}
    </router-link>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "PageTabs",
  props: {
    items: { type: Array, default: () => [] },
    default: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false }
  },
  emits: ["change"],
  setup(props) {
    const route = useRoute();

    const currentItem = computed(() => {
      return props.items.find(item => route.path.includes(item.key)) || props.items[props.default];
    });

    const itemClasses = item => {
      return {
        [item.class]: !!item.class,
        current: item.key == currentItem.value.key,
        disabled: !!props.disabled
      };
    };

    return {
      currentItem,
      itemClasses
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.pageTabs {
  @include flexCentered;

  .item {
    @include transition;
    @include flexCentered();
    padding: addSpace(2);
    border-radius: 3px 3px 0 0;
    color: inherit;
    text-decoration: none;

    &:hover {
      background: rgba($color-off-white, 0.5);
    }
    &.current {
      color: $color-blue;
      background: $color-background;
      box-shadow: 3px -3px 10px -10px $color-black;
    }

    &.disabled {
      opacity: 0.25;
      pointer-events: none;
    }
  }
}
</style>
