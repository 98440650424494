<template>
  <div class="page">
    <SpotlightShine />
    <Icon icon="settings" />
    <Button
      v-bind="Functions.Presets.Button.Full"
      text="SELECT a customised Lux score algorithim"
      @click="$emit('next')"
    />

    <Button
      v-bind="Functions.Presets.Button.Hollow"
      text="I will use the default Lux setting"
      @click="$emit('default')"
    />
  </div>
</template>

<script>
import Functions from "@/functions";
import Button from "@/components/atoms/Button.vue";
import Icon from "@/components/atoms/Icon.vue";
import SpotlightShine from "@/components/atoms/SpotlightShine.vue";
export default {
  name: "Initial",
  emits: ["next", "default"],
  components: {
    Button,
    Icon,
    SpotlightShine
  },
  setup() {
    return {
      Functions
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  @include flexCentered(column);
  @include maxWidth($size-breakpoint-small);
  padding: addSpace(2);

  .icon {
    width: 35vh;
    height: 35vh;
    color: darken($color-blue, 10%);
    margin-bottom: addSpace(-10);
  }
  .button {
    width: 100%;
    margin-top: addSpace(4);
  }
}
</style>
