<template>
  <div class="radio-input-field" :class="classObject">
    <label v-if="label" v-html="label"></label>
    <div class="hint" v-if="hint" v-html="hint"></div>
    <div class="radio-input-field__wrapper">
      <div
        class="option"
        :class="{ checked: isChecked(option) }"
        v-for="option in options"
        :key="generatedKey(option.key)"
        v-on:keyup="selectOption(option, $event)"
        v-on:click="selectOption(option, $event)"
      >
        <input
          :type="type"
          :id="generatedKey(option.key)"
          :aria-label="name"
          :name="name"
          :checked="isChecked(option)"
          :disabled="disabled"
          @focus="onFocus"
          @blur="onBlur"
        />
        <label :for="option.key">
          <div class="text" v-html="$t(option.label)"></div>
          <Icon :icon="option.icon || icon" v-if="option.icon || icon" :class="{ left: iconPosition == 'left' }" />
        </label>
      </div>
      <LoadingBar v-if="loading" class="loading" :loading="loading" :transparent="true" />
    </div>
    <div class="byline" v-if="byline" v-html="byline"></div>
    <transition name="maxH">
      <div
        class="radio-input-field__validation-message"
        data-test="validation-message"
        v-if="errorMessage"
        v-html="$t(errorMessage, $props)"
      ></div>
    </transition>
  </div>
</template>

<script>
import { reactive, ref, computed, nextTick } from "vue";
import _ from "lodash";
import C from "@/assets/constants";
import Icon from "./Icon.vue";
import LoadingBar from "./LoadingBar.vue";
export default {
  name: "RadioInput",
  components: {
    Icon,
    LoadingBar
  },
  props: {
    disabled: { type: Boolean, default: false },
    byline: { type: String, default: null },
    direction: { type: String, default: "horizontal" },
    hint: { type: String, default: null },
    icon: { type: String, default: null },
    iconPosition: { type: String, default: "left" },
    kind: { type: String, default: "selector" },
    label: { type: String, default: null },
    loading: { type: Boolean, default: false },
    modelValue: { type: [String, Number, Boolean], default: "" },
    options: { type: Array, default: () => [] },
    name: { type: String, default: null },
    readonly: { type: Boolean, default: false },
    tabindex: { type: Number, default: null },
    type: { type: String, default: "radio" },
    validation: { type: Function, default: () => true },
    validation_message: { type: [String, Object], default: null }
  },
  emits: ["blur", "focus", "keydown", "update:modelValue", "valid"],
  setup(props, context) {
    const isActive = ref(false);
    const isChanged = ref(false);
    const isTouched = ref(false);
    const isValid = ref(true);
    const errorMessage = ref("");
    const lastKeyDown = reactive({});

    const generatedKey = key => {
      return key + (props.name || props.label || "");
    };
    const isChecked = option => {
      return option.key == props.modelValue;
    };
    const selectOption = (option, event) => {
      isTouched.value = true;
      if (event && event.keyCode === C.SPACEBAR_KEYCODE) {
        context.emit("update:modelValue", option.key);
      }
      // We need to check the mouse position because the click event is fired even on keyboard events
      // for radio buttons as per the browser spec. https://github.com/facebook/react/issues/7407
      if (
        event &&
        event.type === C.EVENT_TYPE_CLICK &&
        event.clientX !== 0 &&
        event.offsetX !== 0 &&
        event.clientY !== 0 &&
        event.offsetY !== 0
      ) {
        context.emit("update:modelValue", option.key);
        nextTick(() => {
          validate(props.modelValue);
        });
      }
    };
    const validate = value => {
      let valid = props.validation ? props.validation(value) : true;
      isValid.value = !isTouched.value || valid === true;
      if (isValid.value) {
        errorMessage.value = "";
      } else {
        if (valid === false) {
          if (props.validation_message) {
            errorMessage.value = props.validation_message;
          } else {
            errorMessage.value = "";
          }
        } else {
          errorMessage.value = valid;
        }
      }
      context.emit("valid", isValid.value);
    };

    const onKeyDown = event => {
      lastKeyDown.target = {
        value: _.get(event, "target.value"),
        selectionStart: _.get(event, "target.selectionStart"),
        selectionEnd: _.get(event, "target.selectionEnd")
      };
      context.emit("keydown", event);
    };
    const onInput = event => {
      if (event.keyCode == C.KEYCODE.TAB || event.keyCode == C.KEYCODE.SHIFT) return;
      isChanged.value = true;
      if (event.keyCode == C.KEYCODE.ENTER && event.key == "Enter") {
        isTouched.value = true;
      }
      if (props.forceinput) {
        let match = event.target.value.match(props.forceinput) || !_.get(event, "target.value.length");
        if (!match || match.input !== match[0]) {
          event.target.value = _.get(lastKeyDown, "target.value") || "";

          if (_.lowerCase(event.target.type) in C.InputTypeForSelectionAttr) {
            event.target.selectionStart = _.get(lastKeyDown, "target.selectionStart");
            event.target.selectionEnd = _.get(lastKeyDown, "target.selectionEnd");
          }
        }
      }
      context.emit("update:modelValue", event.target.value);
      nextTick(() => {
        validate(props.modelValue);
      });
    };
    const onFocus = event => {
      isActive.value = true;
      context.emit("focus", event);
    };
    const onBlur = event => {
      isActive.value = false;
      if (isChanged) {
        isTouched.value = true;
      }
      nextTick(() => {
        validate(props.modelValue);
      });
      context.emit("blur", event);
    };

    const isRequired = computed(() => _.isFunction(props.validation) && !props.validation.call("", context));
    const classObject = computed(() => {
      return {
        ["radio-input-field--type-" + props.type]: !!props.type,
        [`radio-input-field--kind-${props.kind}`]: !!props.kind,
        [`radio-input-field--direction-${props.direction}`]: !!props.direction,
        "radio-input-field--active": props.isActive === true,
        "radio-input-field--is-require": !!isRequired.value,
        "radio-input-field--error": !isValid.value,
        "radio-input-field--readonly": props.readonly,
        "radio-input-field--disabled": !!props.disabled
      };
    });

    return {
      isActive,
      isChanged,
      isTouched,
      isValid,
      errorMessage,
      lastKeyDown,

      generatedKey,
      isChecked,
      selectOption,
      validate,
      onKeyDown,
      onInput,
      onFocus,
      onBlur,

      isRequired,
      classObject
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.radio-input-field {
  width: 100%;
  margin-top: addSpace(2);
  margin-bottom: addSpace(2);

  & > label {
    display: block;
    margin-bottom: addSpace();
    text-align: left;
    margin-left: addSpace(3);
    font-size: $text-small-lead;
  }

  .hint {
    margin-left: addSpace(4);
    margin-bottom: addSpace();
  }

  &__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    color: $color-blue;
    background: $color-white;
    outline: 0;
    outline-style: none;
    border-radius: 1.5em;
    overflow: hidden;

    .option {
      @include transition();
      position: relative;
      cursor: pointer;
      flex-grow: 1;
      font-size: $text-note;
      padding: addSpace(3) addSpace(4) addSpace(2);
      & + .option {
        border-left: 1px solid darken($color-blue, 15%);
      }

      &:hover {
        background: darken($color-white, 5%);
      }
      &.checked {
        color: $color-primary;
        background: darken($color-blue, 10%);
      }

      input {
        position: absolute;
        cursor: pointer;
        opacity: 0;
        width: 100%;
        height: 100%;
        margin: 0;
      }

      label {
        @include flexCentered();
        .text + .icon {
          margin-left: addSpace(1);
        }
        .icon.left {
          margin-left: 0;
          margin-right: addSpace(1);
          order: -1;
        }
      }
    }
  }

  .byline {
    float: right;
    margin: addSpace(1) 0 0 addSpace(2);
  }

  &--is-require {
    & > label::after {
      content: " *";
      color: $color-error;
    }
  }

  &--success {
    .radio-input-field__input,
    .radio-input-field__wrapper:after {
      color: getInputElementState(success, inputColor);
      border-color: getInputElementState(success, borderColor) !important;
    }
  }

  &--error {
    .radio-input-field__input,
    .radio-input-field__wrapper:after {
      color: $color-error;
      border-color: $color-error !important;
    }

    .radio-input-field__icon {
      color: $color-error;
    }
  }

  &--readonly {
    pointer-events: none;
    .radio-input-field__input {
      border-color: getInputElementState(disabled, borderColor);
    }
  }

  &--disabled {
    pointer-events: none;

    .radio-input-field__input {
      color: getInputElementState(disabled, inputColor);
      border-color: getInputElementState(disabled, borderColor);
      background-color: getInputElementState(disabled, backgroundColor);
    }
  }

  &__loading {
    position: absolute !important;
    bottom: 0;
    left: addSpace(3);
    width: calc(100% - #{addSpace(6)});
    border-radius: 1em;
  }

  &__validation-message {
    text-align: left;
    padding-top: addSpace(2);
    padding-left: addSpace(3);
    color: $color-error;
  }

  &--direction-vertical {
    .radio-input-field__wrapper {
      @include flexCentered(column);
      align-items: stretch;
    }
    .option + .option {
      border-left: none;
      border-top: 1px solid darken($color-blue, 15%);
    }
  }
}
</style>
