import Initial from "./Initial.vue";
import Persona from "./Persona.vue";
import Final from "./Final.vue";

const steps = [
  {
    path: "initial",
    label: "Initial",
    component: Initial
  },
  {
    path: "persona",
    label: "Investing persona",
    component: Persona
  },
  {
    path: "final",
    label: "Final",
    component: Final
  }
];

export default {
  steps
};
