<template>
  <div class="page">
    <div class="back">
      <Button v-bind="Functions.Presets.Button.Link" icon="arrow-left" text="back" @click="$emit('back')" />
    </div>
    <div class="title">
      What investing persona should we apply?
    </div>

    <div class="icons">
      <div class="left" :class="{ 'common-disabled': Number(fields[0].value) <= 1 }" @click="swipe(-1)">
        <Icon icon="arrow-left" />
      </div>
      <transition name="flip">
        <Icon v-if="!fields[0].value" icon="00-neutral" />
      </transition>
      <transition name="flip">
        <Icon v-if="fields[0].value == 1" icon="01-trend-warrior" />
      </transition>
      <transition name="flip">
        <Icon v-if="fields[0].value == 2" icon="02-balanced-individual" />
      </transition>
      <transition name="flip">
        <Icon v-if="fields[0].value == 3" icon="03-value-explorer" />
      </transition>
      <transition name="flip">
        <Icon v-if="fields[0].value == 4" icon="04-growth-chaser" />
      </transition>
      <transition name="flip">
        <Icon v-if="fields[0].value == 5" icon="05-income-inspector" />
      </transition>
      <div
        class="right"
        :class="{ 'common-disabled': Number(fields[0].value) >= fields[0].preset.options.length }"
        @click="swipe(1)"
      >
        <Icon icon="arrow-right" />
      </div>
    </div>

    <form v-on:submit.prevent="$emit('next')" class="common-form" :class="{ 'common-form-loading': busy }">
      <div class="form-content" :class="{ 'common-disabled': busy }">
        <component :is="f.component" v-for="(f, k) in fields" :key="f.key || k" v-bind="f.preset" v-model="f.value" />
        <div class="form-actions">
          <Button v-bind="Functions.Presets.Button.Link" text="Not sure? Read here" />
          <Button v-bind="Functions.Presets.Button.Submit" text="Next" :disabled="!canSubmit" @click="$emit('next')" />
        </div>
      </div>
    </form>

    <div class="premium">
      Join as a Platinum member and you can create your own customised profile. Interested?
    </div>
  </div>
</template>

<script>
import { markRaw } from "vue";
import { useForm } from "@/components/renderless/form";
import _ from "lodash";
import Functions from "@/functions";
import Button from "@/components/atoms/Button.vue";
import Icon from "@/components/atoms/Icon.vue";
import RadioInput from "@/components/atoms/RadioInput.vue";
export default {
  name: "Persona",
  emits: ["next", "back"],
  components: {
    Button,
    Icon,
    RadioInput
  },
  setup() {
    const formData = useForm([
      {
        key: "persona",
        component: markRaw(RadioInput),
        preset: {
          kind: "selector",
          direction: "vertical",
          iconPosition: "left",
          options: [
            {
              key: "1",
              label: "Trend Warrior"
            },
            {
              key: "2",
              label: "Balanced Individual"
            },
            {
              key: "3",
              label: "Value Explorer"
            },
            {
              key: "4",
              label: "Growth Chaser"
            },
            {
              key: "5",
              label: "Income Inspector"
            }
          ],
          validation: v => !!v
        },
        value: ""
      }
    ]);
    const swipe = direction => {
      let index = _.get(formData, `fields.value[0].preset.options`, []).findIndex(
        o => o.key == formData.fields.value[0].value
      );
      index = index + direction;
      let key = _.get(formData, `fields.value[0].preset.options[${index}].key`, false);
      if (key) {
        formData.fields.value[0].value = key;
      }
    };

    return {
      Functions,
      busy: formData.busy,
      canSubmit: formData.canSubmit,
      currentData: formData.currentData,
      fields: formData.fields,
      swipe
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  @include flexCentered(column);
  align-items: stretch;
  @include maxWidth($size-breakpoint-small);
  padding: addSpace(2);

  .back {
    font-size: $text-small;
  }

  .title {
    font-size: $text-lead;
    margin: addSpace(2) 0;
  }

  .icons {
    position: relative;
    padding-bottom: 100%;
    .left,
    .right {
      @include transition;
      position: absolute;
      width: 30%;
      top: 30%;
      opacity: 0.5;
      cursor: pointer;
      &:hover {
        opacity: 0.75;
      }
      &.common-disabled {
        opacity: 0.1;
      }
    }
    .left {
      left: -30%;
    }
    .right {
      right: -30%;
    }
    .icon {
      position: absolute;
      width: 100%;
    }
  }

  .form-actions {
    @include flexCentered;
    justify-content: space-between;
    margin: addSpace(2) 0;
  }

  .premium {
    font-style: italic;
    margin-top: addSpace(4);
  }
}
</style>
