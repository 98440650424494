<template>
  <div class="luxSettings">
    <transition name="maxH">
      <component :is="currentStep.component" @back="nextStep(-1)" @next="nextStep(1)" />
    </transition>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import _ from "lodash";
import Functions from "@/functions";
import Steps from "./Steps";
export default {
  name: "LuxSettings",
  props: {
    step: { type: [String, Number], default: null }
  },
  setup(props) {
    const router = useRouter();
    const stepToNumber = step => {
      let n = Steps.steps.findIndex(s => s.path == step);
      if (n < 0) {
        n = 0;
      }
      return n;
    };
    const currentStep = computed(() => {
      let stepNumber;
      if (_.isString(props.step)) {
        stepNumber = stepToNumber(props.step);
      } else {
        stepNumber = props.step || 0;
      }
      return Steps.steps[stepNumber];
    });

    const nextStep = direction => {
      let n = stepToNumber(props.step) + direction;
      router.push({ params: { step: Steps.steps[n].path } });
    };

    return {
      Functions,
      currentStep,
      nextStep
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.luxSettings {
  @include flexCentered(column);
  padding: addSpace(2);
}
</style>
