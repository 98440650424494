<template>
  <div class="priceChange" :class="classObject" :style="cssVars">
    <div class="left">
      <div class="percentage">
        <Icon v-if="!zero" :icon="trendIcon" />
        {{ Functions.Formatting.percentage(Math.abs(changePC)) }}
      </div>
      <div class="change">
        <Icon v-if="!zero" :icon="arrowIcon" />
        {{ Functions.Formatting.addCommas(Math.abs(changeDollar), 2) }}
      </div>
    </div>
    <div class="right">
      <div class="price">
        {{ Functions.Formatting.currency(changeValue) }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import _ from "lodash";
import Functions from "@/functions";
import Icon from "@/components/atoms/Icon.vue";
export default {
  name: "FullPriceChange",
  props: {
    company: { type: Object, default: () => {} }
  },
  components: {
    Icon
  },
  setup(props) {
    const changePC = computed(() => {
      return _.get(props.company, "companyData.priceChange['price.change.1D'].changePC", 0);
    });
    const changeDollar = computed(() => {
      return _.get(props.company, "companyData.priceChange['price.change.1D'].changeDollar", 0);
    });
    const changeValue = computed(() => {
      return _.get(props.company, "companyData.priceChange['price.change.1D'].price", 0);
    });

    const cssVars = computed(() => {
      return {
        "--trend": trend
      };
    });

    const classObject = computed(() => {
      return {
        ["priceChange--trend-" + trendColor.value]: !zero.value
      };
    });

    const zero = computed(() => {
      return changePC.value == 0;
    });

    const trend = computed(() => {
      return zero.value ? 0 : changePC.value > 0 ? 1 : -1;
    });

    const trendIcon = computed(() => {
      return trend.value > 0 ? "plus" : "minus";
    });

    const arrowIcon = computed(() => {
      return trend.value > 0 ? "arrow-up" : "arrow-down";
    });

    const trendColor = computed(() => {
      return trend.value > 0 ? "green" : "red";
    });

    return {
      Functions,
      changePC,
      changeDollar,
      changeValue,
      cssVars,
      classObject,
      zero,
      trend,
      trendIcon,
      arrowIcon
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.priceChange {
  @include transition;
  @include flexCentered;
  position: relative;

  .price {
    font-size: 2em;
  }

  .left {
    .icon {
      font-size: 0.75em;
    }
  }

  &--trend-green .left {
    color: $color-success;
  }
  &--trend-red .left {
    color: $color-error;
  }
}
</style>
