<template>
  <div class="luxBreakdown" :style="cssVars">
    <div class="box back">
      <div class="item" v-for="item in itemList" :key="item" :class="luxClassByType(item)">
        <div class="number" v-text="luxValueByType(item)"></div>
        <Icon :icon="`lux-${item}`" />
        <div class="value" v-text="luxStringByType(item)"></div>
        <div class="label" v-text="item"></div>
        <div class="glow"></div>
      </div>
    </div>
    <div class="box front">
      <div class="item" v-for="item in itemList" :key="item" :class="luxClassByType(item)">
        <div class="number" v-text="luxValueByType(item)"></div>
        <Icon :icon="`lux-${item}`" />
        <div class="value" v-text="luxStringByType(item)"></div>
        <div class="label" v-text="item"></div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { computed } from "vue";
import Functions from "@/functions";
import Icon from "@/components/atoms/Icon.vue";
export default {
  name: "LuxBreakdown",
  components: {
    Icon
  },
  props: {
    company: { type: Object, default: () => {} }
  },
  setup(props) {
    const itemList = ["growth", "value", "balanced", "income", "trend"];

    const luxByType = type => {
      return _.get(props.company, `companyData.luxScore["lux.${type}"].value`, null);
    };
    const luxPercentageByType = type => {
      return luxByType(type) * 10;
    };
    const luxValueByType = type => {
      return Functions.Formatting.restrictDecimals(luxByType(type), 0);
    };
    const luxStringByType = type => {
      return Functions.Company.luxToLabel(luxByType(type));
    };

    const luxClassByType = type => {
      let lux = luxByType(type);
      return {
        [`lux-n-${Functions.Formatting.restrictDecimals(lux, 1)}`]: true,
        [`lux-l-${Functions.Company.luxToLabel(lux)}`]: true
      };
    };

    const path = computed(() => {
      let path = "0% 100%";
      itemList.forEach((item, i) => {
        let x = 10 + i * 20;
        let y = 100 - Functions.Math.nonLinearPercentage(luxPercentageByType(item));
        if (i == 0) {
          path += `, 0% ${y}%`;
        }
        path += `, ${x}% ${y}%`;
        if (i == itemList.length - 1) {
          path += `, 100% ${y}%`;
        }
      });
      path += ", 100% 100%";
      return path;
    });

    const cssVars = computed(() => {
      return {
        "--path": path.value
      };
    });

    return {
      itemList,
      luxByType,
      luxPercentageByType,
      luxValueByType,
      luxStringByType,
      luxClassByType,
      cssVars
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";
$wave-color: darken($color-blue, 10%);

.luxBreakdown {
  position: relative;
  width: 100%;
  height: 100px;

  .box {
    @include flexCentered;
    @include transition;
    position: absolute;
    width: 100%;
    height: 100%;

    @include mq($to: medium) {
      height: 40px;
    }

    &.back {
      z-index: z("flat");
      color: $color-off-white;

      .item {
        &.lux-l-Radiant .glow {
          box-shadow: 0 0 4em 3em $color-light;
          @include mq($to: medium) {
            box-shadow: 0 0 3em 2em $color-light;
          }
        }
        &.lux-l-Bright .glow {
          box-shadow: 0 0 4em 2em $color-light;
          @include mq($to: medium) {
            box-shadow: 0 0 3em 1em $color-light;
          }
        }
      }
    }

    &.front {
      z-index: z("front");
      color: $color-white;
      background: $wave-color;
      clip-path: polygon(var(--path));
    }

    .item {
      @include flexCentered(column);
      position: relative;
      flex-grow: 1;

      .number {
        position: absolute;
        font-size: 4em;
        font-weight: 100;
        color: rgba($color-black, 0.25);
        bottom: addSpace(4);
        opacity: 0.15;
        @include mq($to: medium) {
          font-size: 2em;
          font-weight: 300;
          bottom: addSpace(2);
        }
      }

      .icon {
        position: absolute;
        padding: addSpace(1);
        width: 60px;
        height: 60px;
        opacity: 0.1;
        @include mq($to: medium) {
          display: none;
        }
      }
      .value {
        font-size: 1.1em;
        font-weight: 400;

        @include mq($to: medium) {
          font-size: 0.85em;
        }
      }
      .label {
        position: absolute;
        top: 80%;
        font-size: 0.8em;
        letter-spacing: 2px;
        font-style: italic;
        color: $color-gray;
        display: inline-block;
      }

      .glow {
        position: absolute;
        width: 0.1em;
        height: 0.1em;
        border-radius: 99em;
      }

      &.lux-l-Dull {
        .value,
        .number {
          color: $color-gray;
        }
      }
      &.lux-l-Neutral {
        .value,
        .number {
          color: $color-white;
        }
      }
      &.lux-l-Radiant {
        .value,
        .number {
          color: $color-warning;
        }
      }
      &.lux-l-Bright {
        .value,
        .number {
          color: $color-light;
        }
      }
    }
  }
}
</style>
