<template>
  <div class="chartContainer">
    <div :id="container_id"></div>
    <div ref="chart"></div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
export default {
  name: "MediumWidget",
  props: {
    id: { type: String, default: null },
    symbols: { type: [String, Array], default: () => [] },
    chartOnly: { type: Boolean, default: false },
    width: { type: [String, Number], default: "100%" },
    height: { type: [String, Number], default: 250 },
    locale: { type: String, default: "en" },
    colorTheme: { type: String, default: "light" },
    gridLineColor: { type: String, default: "#F0F3FA" },
    trendLineColor: { type: String, default: "#172567" },
    fontColor: { type: String, default: "#787B86" },
    underLineColor: { type: String, default: "#e9ecfa" },
    isTransparent: { type: Boolean, default: true },
    autosize: { type: Boolean, default: true },
    showFloatingTooltip: { type: Boolean, default: true }
  },
  setup(props) {
    const chart = ref(null);
    const container_id = computed(() => props.id || Date.now());

    onMounted(() => {
      let chartProps = document.createElement("script");
      chartProps.setAttribute("type", "text/javascript");
      chartProps.appendChild(
        document.createTextNode(`new TradingView.MediumWidget(
          {
            "symbols": ${JSON.stringify(props.symbols)},
            "chartOnly": ${props.chartOnly},
            "width": "${props.width}",
            "height": "${props.height}",
            "locale": "${props.locale}",
            "colorTheme": "${props.colorTheme}",
            "gridLineColor": "${props.gridLineColor}",
            "trendLineColor": "${props.trendLineColor}",
            "fontColor": "${props.fontColor}",
            "underLineColor": "${props.underLineColor}",
            "bottomColor": "${props.underLineColor}",
            "isTransparent": ${props.isTransparent},
            "autosize": ${props.autosize},
            "showFloatingTooltip": ${props.showFloatingTooltip},
            "container_id": "${container_id.value}"
          }
        );`)
      );
      chart.value.appendChild(chartProps);
    });

    return {
      chart,
      container_id
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.chartContainer {
  position: relative;
  border-bottom: addSpace(6) solid #fff;
  margin-right: -5px;

  div {
    margin-bottom: addSpace(-6);
  }
}
</style>
